import { useEffect, useState } from 'react'

import { HStack, Progress, VStack } from '@chakra-ui/react'

import { PostgrestResponse } from '@supabase/supabase-js'
import supabase from 'config/supabase-client'
import { TABLES_NAME, VIEWS } from 'constants/tables'
import useMounted from 'hooks/useMounted'
import { IDealRating } from 'interfaces/models'
import { IViewScoutInSector } from 'interfaces/views'
import { formatCurrency, formatDecimal } from 'utils/numbers'

import {
  Label,
  ProminentNumber,
  TagWithDot,
  TextValue,
} from 'components/common/other/deal-details'
import { FlexField } from 'components/common/other/deal-details/FlexLayout'
import { getRateColor } from 'components/common/other/deal-details/utils'

import { showArrayAsStr } from 'modules/deals/utils'

import {
  COMPANY_FIELDS,
  CURRENCY_FIELDS,
  FUNDRAISE_FIELDS,
  HEAD_FIELDS,
  INDICATED_INTEREST_FIELDS,
  RATING_LABELS,
} from '../constants/deal-details'
import { TLatestDealActivities } from '../interfaces/deal-detail'
import { IDealRow } from '../interfaces/deal-table'
import useDealBonusQuestions, {
  TDealExtraQuestions,
} from './useDealBonusQuestions'

/**
 * Transforms the provided map of fields to a flexible field format based on the given deal.
 *
 * @param {Record<string, string>} mapFields - The map of fields to transform.
 * @param {IDealRow} deal - The deal row to use for transformation.
 * @return {Array<{ label: string, value: string | number }>} The transformed flexible fields.
 */
const transformToFlexField = (
  mapFields: Record<string, string>,
  deal: IDealRow,
) => {
  return Object.entries(mapFields).map(([key, label]) => {
    return {
      label,
      value:
        typeof deal[key as keyof IDealRow] === 'number'
          ? CURRENCY_FIELDS.includes(key)
            ? formatCurrency(deal[key as keyof IDealRow])
            : formatDecimal(deal[key as keyof IDealRow])
          : showArrayAsStr(deal[key as keyof IDealRow]),
    }
  })
}

/**
 * Custom hook for extracting deal details.
 *
 * @param {{ deal: IDealRow }} deal - the deal object
 * @return {{ sectors: string[], domains: string[], headFields: FlexField[], ratingFields: FlexField[], companyInformationFields: FlexField[], fundraiseFields: FlexField[] }} extracted deal details
 */
const useDealDetails = ({ deal }: { deal: IDealRow }) => {
  const isMounted = useMounted()
  const [dealActivities, setDealActivities] =
    useState<TLatestDealActivities[]>()

  const bonusQuestions: TDealExtraQuestions[] = useDealBonusQuestions({
    dealId: deal?.deal_id,
  })

  const indicatedInterestFields: FlexField[] = transformToFlexField(
    INDICATED_INTEREST_FIELDS,
    deal,
  )

  const fundraiseFields: FlexField[] = transformToFlexField(
    FUNDRAISE_FIELDS,
    deal,
  )

  const companyInformationFields: FlexField[] = transformToFlexField(
    COMPANY_FIELDS,
    deal,
  )

  const headFields: FlexField[] = Object.entries(HEAD_FIELDS).map(
    ([key, label]) => {
      return {
        label,
        value: (() => {
          const value = deal[key as keyof IDealRow]
          if (key === 'maven_rating_avg_in_sector') {
            return (
              <HStack gap="12px">
                <ProminentNumber>
                  {parseFloat(value + '')?.toFixed(1) ?? 0}
                </ProminentNumber>
                <Label>
                  {deal?.percent_mavens_have_evaluated ?? 0}% Quorum
                </Label>
              </HStack>
            )
          }

          if (key === 'deal_rank') {
            return <ProminentNumber>{value ?? 0}</ProminentNumber>
          }

          if (key === 'available_tickets') {
            return (
              <HStack gap="12px">
                <TextValue>{parseFloat(value ?? 0)?.toFixed(1) ?? 0}</TextValue>

                <VStack alignItems="start" gap={0}>
                  <TextValue fontSize="11.2px">
                    {parseFloat(deal?.percent_left ?? 0)?.toFixed(1) ?? 0} %
                    left
                  </TextValue>
                  <Progress
                    colorScheme="progressActiveIntrosColor"
                    w="100px"
                    bg={'#876CEA'}
                    size="sm"
                    borderRadius="full"
                    h="2.4px"
                    value={100 - parseFloat(deal?.percent_left ?? 0)}
                  />
                </VStack>
              </HStack>
            )
          }

          if (typeof value === 'number' && key !== 'year_founded') {
            return CURRENCY_FIELDS.includes(key)
              ? formatCurrency(value)
              : formatDecimal(value)
          }

          return showArrayAsStr(value)
        })(),
      }
    },
  )

  const ratingFields = Object.entries(RATING_LABELS).map(([key, label]) => {
    return {
      label,
      value: (
        <TagWithDot
          dotColor={getRateColor((deal[key as keyof IDealRow] ?? 0) as number)}
        >
          {parseFloat(deal[key as keyof IDealRow] + '')?.toFixed(1) ??
            deal[key as keyof IDealRow]}
        </TagWithDot>
      ),
    }
  })

  // let sectors: string[] = []
  // let domains: string[] = []
  // if (deal?.['sectors_and_domains']) {
  //   const segments = deal?.['sectors_and_domains']?.split(',')

  //   segments.forEach((segment: string) => {
  //     const parts = segment.split(' > ')
  //     sectors.push(parts[0].trim())
  //     domains.push(parts[2].trim())
  //   })
  // }

  // sectors = uniq(sectors)
  // domains = uniq(domains)
  /**
   * Merge sector experience in Maven Insight.
   *
   * @param {Array<IDealRating & { users: { avatar: string } }>} mavenRatingsOfDeal - array of maven ratings of deal
   * @param {Array<IViewScoutInSector>} scoutExpInSector - array of scout experience in sector
   * @return {Array<TLatestDealActivities>} array of latest deal activities
   */
  const mergeSectorExpInMavenInsight = (
    mavenRatingsOfDeal: (IDealRating & { users: { avatar: string } } & {
      view_count_user_stats: {
        user_id: string
        count_evals: string
        count_intros: string
      }
    })[],
    scoutExpInSector: IViewScoutInSector[],
  ): TLatestDealActivities[] => {
    return mavenRatingsOfDeal.map((mavenRating) => {
      const expInSectors: IViewScoutInSector[] = scoutExpInSector.filter(
        (scout) => {
          return scout.scout_id === mavenRating.maven_user_id
        },
      )

      return {
        ...mavenRating,
        expInSectors,
      }
    })
  }

  useEffect(() => {
    /**
     * Fetches sector experience data from the database.
     *
     * @return {Promise<IViewScoutInSector[] | []>} The sector experience data or an empty array.
     */
    const fetchSectorExperience = async (): Promise<
      IViewScoutInSector[] | []
    > => {
      try {
        const {
          data: scoutExpInSector,
          error,
        }: PostgrestResponse<IViewScoutInSector> = await supabase
          .from(VIEWS.view_scout_exp_in_sector)
          .select('*')

        if (error) {
          return []
        }

        return scoutExpInSector
      } catch (error) {
        return []
      }
    }
    /**
     * Fetches the latest Maven insight for deal activities.
     *
     * @return {Promise<TLatestDealActivities[]>} An array of latest deal activities
     */
    const fetchLatestMavenInsight = async (): Promise<
      TLatestDealActivities[]
    > => {
      try {
        const {
          data: mavenRatingsOfDeal,
          error,
        }: PostgrestResponse<
          IDealRating & { users: { avatar: string } } & {
            view_count_user_stats: {
              user_id: string
              count_evals: string
              count_intros: string
            }
          }
        > = await supabase
          .from(TABLES_NAME.deal_maven_ratings)
          .select(
            '*, users(avatar, location_name, user_id), view_count_user_stats(user_id, count_evals, count_intros)',
          )
          .order('created_at', { ascending: false })
          .eq('deal_id', deal?.deal_id)

        const scoutExpInSector = await fetchSectorExperience()
        const dealActivities: TLatestDealActivities[] =
          mergeSectorExpInMavenInsight(
            mavenRatingsOfDeal ?? [],
            scoutExpInSector,
          )

        if (error) {
          return []
        }

        return dealActivities
      } catch (error) {
        return []
      }
    }

    const fetchData = async () => {
      const latestMavenActivities = await fetchLatestMavenInsight()
      setDealActivities([...latestMavenActivities])
    }

    deal?.deal_id && isMounted && fetchData()
  }, [deal?.deal_id, isMounted])

  useEffect(() => {
    setDealActivities([])
  }, [])

  return {
    // sectors,
    // domains,
    indicatedInterestFields,
    headFields,
    ratingFields,
    companyInformationFields,
    fundraiseFields,
    dealActivities,
    bonusQuestions,
  }
}

export default useDealDetails
