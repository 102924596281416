import {
  HStack,
  Icon,
  Image,
  Link,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'

import { LOGO_PATH } from 'constants/common'
import { EMAIL_TO, LINKEDIN_URL, TWITTER_URL } from 'constants/endpoints'

import { Email, LinkedIn, Twitter } from 'components/common/Icons'
import { WithCenterLayout } from 'components/hoc'

export const Footer = () => (
  <WithCenterLayout mt="100px" w="full" color="white" bg="#000" pos="relative">
    <WithCenterLayout
      w="1366px"
      p={['24px 16px', '24px 16px', '48px 41px', '48px 41px', '48px 41px']}
      justifyContent="space-between"
      alignItems="start"
      bg="#000"
      pos="relative"
      bottom="0"
    >
      <VStack
        gap={[0.5, 0.5, 0.5, 2.75, 2.75]}
        alignItems="start"
        justifyContent="start"
      >
        <Image
          loading="lazy"
          src={LOGO_PATH}
          alt="deploy capital logo"
          width="9.25rem"
        />
        <Text as="h3" fontSize="14px" fontWeight="medium" lineHeight="22px">
          Copyright ©deploy. All rights reserved.
        </Text>
      </VStack>
      <VStack>
        <HStack gap={5}>
          <Link href={TWITTER_URL}>
            <Icon boxSize={6} as={Twitter}></Icon>
          </Link>
          <Link href={LINKEDIN_URL}>
            <Icon boxSize={6} as={LinkedIn}></Icon>
          </Link>
          <Link href={`mailto:${EMAIL_TO}`}>
            <Icon boxSize={6} as={Email}></Icon>
          </Link>
        </HStack>
        {/* <Text as="h3" fontSize="14px">
        © deploy. All rights reserved.
      </Text> */}
      </VStack>
    </WithCenterLayout>
  </WithCenterLayout>
)
