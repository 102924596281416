import { ReactNode } from 'react'

import {
  Button,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  LinkProps,
  Slide,
  StackProps,
  Text,
  chakra,
  shouldForwardProp,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'

import { ArrowForwardIcon, HamburgerIcon, SearchIcon } from '@chakra-ui/icons'

import { LOGO_LIGHT_MODE_PATH, LOGO_PATH } from 'constants/common'
import { isValidMotionProp, motion } from 'framer-motion'
import { useMobile } from 'hooks'
import { useHistory } from 'react-router'

import ToggleMode from 'components/common/other/ToggleMode'
import { WithCenterLayout, WithResponsiveLayout } from 'components/hoc'

import { Login } from 'modules/authentication/pages'

const headerStyles = {
  items: {
    _hover: {
      _after: {
        width: '100%',
        left: 0,
      },
      _before: {
        width: '100%',
        left: 0,
      },
    },
    _after: {
      content: '""',
      pos: 'absolute',
      top: 'calc(100% + 5px)',
      width: 0,
      right: 0,
      height: '3px',
      bgColor: '#FFFFFF',
      transition: 'width .2s cubic-bezier(0.29, 0.18, 0.26, 0.83)',
    },
    _before: {
      content: '""',
      pos: 'absolute',
      top: 'calc(100% + 5px)',
      width: 0,
      right: 0,
      height: '3px',
      bgColor: '#FFFFFF',
      transition: 'width .25s cubic-bezier(0.51, 0.18, 0, 0.88) .1s',
    },
  },
}

const Header = ({ children }: { children: ReactNode }) => {
  const isMobile = useMobile()
  const bgByMode = useColorModeValue('darkMode', 'lightMode')

  return (
    <Slide direction="top" in={true} style={{ zIndex: 10, position: 'static' }}>
      <HStack
        as="header"
        p={['1rem', '1rem', '1rem', '12px 41px', '12px 41px']}
        w="100vw"
        bg={bgByMode}
        justifyContent={isMobile ? 'space-between' : 'space-between'}
      >
        {children}
      </HStack>
    </Slide>
  )
}

export const HeaderLogo = (props?: LinkProps) => {
  const logoPath = useColorModeValue(LOGO_PATH, LOGO_LIGHT_MODE_PATH)
  return (
    <Link href="/" {...props}>
      <Image
        loading="lazy"
        src={logoPath}
        alt="deploy capital logo"
        transition="width 0.2s ease"
      />
    </Link>
  )
}

// const items = [
//   { name: 'About', path: 'place-smarter-bets' },
//   { name: 'Investors', path: 'capitalization' },
//   { name: 'Experts', path: 'capitalization' },
//   { name: 'Founders', path: 'capitalization' },
//   { name: 'Connect', path: 'founding-team' },
// ]

const items = [
  { name: 'About', path: 'about' },
  { name: 'Investors', path: 'investor' },
  { name: 'Experts', path: '/experts' },
  { name: 'Founders', path: '/founders' },
  { name: 'Connect', path: '/connect' },
]

function scrollToSection(sectionId: string) {
  const element = document.getElementById(sectionId)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
})

export const HeaderItems = (props?: StackProps) => {
  const history = useHistory()
  const isMobile = useMobile()
  const color = useColorModeValue(
    isMobile ? '#FFFFFF' : '#FFFFFF',
    isMobile ? 'darkMode' : 'rgba(16, 16, 16, 0.60)',
  )

  return (
    <WithResponsiveLayout as="ul" gap={6} {...props}>
      {items.map(({ name, path }, index) => {
        return (
          <ChakraBox
            key={`${name}**${index}`}
            pos="relative"
            {...headerStyles.items}
            onClick={() => {
              // scrollToSection(path)
              history.push(path)
            }}
          >
            <HStack
              contentEditable={false}
              key={name}
              as="li"
              cursor="pointer"
              gap={0}
            >
              <Text color={color} fontSize="xl" fontWeight="medium">
                {name}
              </Text>
            </HStack>
          </ChakraBox>
        )
      })}
    </WithResponsiveLayout>
  )
}

export const HeaderSearch = () => {
  return <SearchIcon boxSize={4} color="white" />
}

export const HeaderSignInDesktop = () => {
  const disclosureProps = useDisclosure()

  return (
    <>
      {/* <Button
        variant="lending"
        onClick={() => (window.location.href = DEPLOY_TIPS_URL)}
        rightIcon={<ArrowForwardIcon />}
      >
        Startup log in
      </Button> */}

      <Button
        variant="lending"
        borderColor="accent.main01 !important"
        color="accent.main01 !important"
        onClick={disclosureProps?.onOpen}
        rightIcon={
          <ArrowForwardIcon
            bg="accent.main01 !important"
            color="accent.main01"
          />
        }
        _hover={{
          bg: 'accent.main02',
          color: 'white !important',
          svg: {
            bg: 'white !important',
            color: '#101010',
          },
        }}
      >
        Investor log in
      </Button>
      {/* <ToggleMode /> */}
      <LogInModal {...disclosureProps} />
    </>
  )
}

const HeaderRightMobile = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button
        variant="ghost"
        onClick={onOpen}
        as={IconButton}
        w={8}
        icon={<HamburgerIcon w="30px" h="30px" color="accent.main01" />}
      />
      <Drawer onClose={onClose} isOpen={isOpen} size="xxl">
        <DrawerOverlay backdropFilter="auto" backdropBlur="20px" />
        <DrawerContent bg="rgba(16, 16, 16, 0.13)">
          <DrawerCloseButton
            zIndex={1}
            color="accent.main01"
            sx={{
              '> svg': { w: '1.25rem', h: '1.25rem' },
              pos: 'absolute',
              left: '24px',
              top: '34px',
            }}
          />
          <DrawerBody mt={5}>
            <WithCenterLayout flexDir="column" h="full" pos="relative">
              <HeaderLogo pos="absolute" left="42px" top="0" />
              <HeaderItems gap={15} />
              {/* <ToggleMode pos="absolute" bottom="1.5rem" /> */}
            </WithCenterLayout>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

const LogInModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  // const [isInvestorLogin, toggle] = useBoolean()

  const handleClose = () => {
    // toggle.off()
    onClose?.()
  }

  return (
    <>
      <Drawer onClose={handleClose} isOpen={isOpen} size="xxl">
        <DrawerOverlay backdropFilter="auto" backdropBlur="20px" />
        <DrawerContent bg="rgba(16, 16, 16, 0.13)">
          <DrawerBody mt={5}>
            <WithCenterLayout h="full" w="full">
              <WithCenterLayout
                w={['full', 'full', 'full', 'calc(100% / 3)', 'calc(100% / 4)']}
                gap="1rem"
                flexDir="column"
                h="fit-content"
                bg="white"
                borderRadius="24px"
                p="20px"
                alignItems="start"
              >
                <HStack w="full" justifyContent="space-between">
                  <Text fontSize="24px" fontWeight="bold" color="darkMode">
                    Log in
                  </Text>
                  <CloseButton
                    onClick={handleClose}
                    w="1.25rem"
                    h="1.25rem"
                    color="rgba(16, 16, 16, 0.6)"
                    // opacity={0.4}
                  />
                </HStack>
                {/* {!isInvestorLogin && (
                  <VStack w="full" gap="0.5rem">
                    <Button
                      minH="54px"
                      w="full"
                      borderRadius={4}
                      color="darkMode"
                      bg="rgba(16, 16, 16, 0.06)"
                      p="1rem"
                      fontSize="md"
                      fontWeight="medium"
                      justifyContent="space-between"
                      onClick={() => (window.location.href = DEPLOY_TIPS_URL)}
                      rightIcon={
                        <ChevronRight
                          w="1.25rem"
                          h="1.25rem"
                          color="accent.main01"
                        />
                      }
                    >
                      Startup
                    </Button>
                    <Button
                      minH="54px"
                      w="full"
                      p="1rem"
                      color="darkMode"
                      fontSize="md"
                      fontWeight="medium"
                      borderRadius={4}
                      bg="rgba(16, 16, 16, 0.06)"
                      justifyContent="space-between"
                      onClick={toggle.toggle}
                      rightIcon={
                        <ChevronRight
                          w="1.25rem"
                          h="1.25rem"
                          color="accent.main01"
                        />
                      }
                    >
                      Investor
                    </Button>
                  </VStack>
                )} */}
                {/* {isInvestorLogin && <Login />} */}
                <Login />
              </WithCenterLayout>
            </WithCenterLayout>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export const HeaderRight = () => {
  const isMobile = useMobile()

  return <HStack gap={6}>{!isMobile && <HeaderSignInDesktop />}</HStack>
}

export const HeaderLeft = () => {
  const isMobile = useMobile()
  const disclosureProps = useDisclosure()

  return (
    <>
      <HStack w="full" justifyContent="space-between">
        <HStack gap={['12px', '12px', '12px', '12px', '12px']} w="full">
          {isMobile && <HeaderRightMobile />}
          <HeaderLogo />
          {!isMobile && (
            <WithCenterLayout w="full">
              <HeaderItems />
            </WithCenterLayout>
          )}
        </HStack>

        {isMobile && (
          <>
            <Button
              variant="lending"
              borderColor="accent.main01 !important"
              color="accent.main01 !important"
              onClick={disclosureProps?.onOpen}
              p="12px"
              pl="1rem"
              gap="0.5rem"
              minH="44px"
              fontSize="md"
              rightIcon={
                <ArrowForwardIcon
                  bg="accent.main01 !important"
                  color="accent.main01"
                />
              }
              _hover={{
                bg: 'accent.main02',
                color: 'white !important',
                svg: {
                  bg: 'white !important',
                  color: '#101010',
                },
              }}
            >
              Login
            </Button>
            <LogInModal {...disclosureProps} />
          </>
        )}
      </HStack>
    </>
  )
}

export default Header
