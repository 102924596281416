import {
  Avatar,
  AvatarProps,
  Button,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'

import { AiFillAppstore } from 'react-icons/ai'

import TextValue from '../TextValue'

const LocationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.87533 5.4165C6.07116 5.4165 5.41699 6.07067 5.41699 6.87567C5.41699 7.67984 6.07116 8.33317 6.87533 8.33317C7.67949 8.33317 8.33366 7.67984 8.33366 6.87567C8.33366 6.07067 7.67949 5.4165 6.87533 5.4165ZM6.87533 9.58317C5.38199 9.58317 4.16699 8.369 4.16699 6.87567C4.16699 5.3815 5.38199 4.1665 6.87533 4.1665C8.36866 4.1665 9.58366 5.3815 9.58366 6.87567C9.58366 8.369 8.36866 9.58317 6.87533 9.58317Z"
      fill="white"
    />
    <mask
      id="mask0_85_987"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="14"
      height="17"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0H13.7496V16.25H0V0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_85_987)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.87451 1.25C3.77285 1.25 1.24951 3.7975 1.24951 6.9275C1.24951 10.91 5.93618 14.79 6.87451 14.9967C7.81284 14.7892 12.4995 10.9092 12.4995 6.9275C12.4995 3.7975 9.97618 1.25 6.87451 1.25ZM6.87451 16.25C5.37951 16.25 -0.000488281 11.6233 -0.000488281 6.9275C-0.000488281 3.1075 3.08368 0 6.87451 0C10.6653 0 13.7495 3.1075 13.7495 6.9275C13.7495 11.6233 8.36951 16.25 6.87451 16.25Z"
        fill="white"
      />
    </g>
  </svg>
)

const UserID = ({
  avatar,
  id,
  location,
  avatarProps,
  hasRequestContact = false,
  shouldShowAvatar = true,
}: {
  avatar?: string | undefined | null
  id: number | string
  location?: string | undefined | null
  avatarProps?: AvatarProps
  hasRequestContact?: boolean | undefined
  shouldShowAvatar?: boolean | undefined
}) => {
  return (
    <HStack gap="12px">
      {shouldShowAvatar && (
        <Avatar
          w="28px"
          h="28px"
          src={avatar ?? 'images/userid.png'}
          // border="0.5px solid #101010"
          borderRadius="full"
          overflow="hidden"
          bg="#e3e3e36b"
          sx={{
            '> img': { filter: 'blur(7.5px)' },
          }}
          {...avatarProps}
        />
      )}

      <VStack h="full" alignItems="start" gap={1}>
        <TextValue fontSize="15px">{id}</TextValue>
        {location && (
          <HStack gap="4px">
            <LocationIcon />
            <Text color="white" fontSize="sm" fontWeight="normal">
              {location}
            </Text>
          </HStack>
        )}
        {hasRequestContact && (
          <Button
            size={['sm', 'sm']}
            w={['full']}
            variant="filled"
            borderRadius={'8px !important'}
            leftIcon={<AiFillAppstore />}
            gap="0px"
            sx={{ py: '2px !important' }}
          >
            Request Contact
          </Button>
        )}
      </VStack>
    </HStack>
  )
}

export default UserID
